import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "shop_section"
};
const _hoisted_2 = {
  class: "shop_title"
};
const _hoisted_3 = {
  class: "shop_description"
};
const _hoisted_4 = {
  class: "shop_content-wrapper"
};
const _hoisted_5 = {
  key: 1
};
import { onMounted, ref } from "vue";
import ShopItem from "../ShopItem.vue";
import { ShopItemsStore } from "@/stores/ShopItemsStore";
import { EtcItemEnum } from "@/enums/EtcItemEnum";
import { useToast } from "@brackets/vue-toastification";
export default /*@__PURE__*/_defineComponent({
  __name: 'ContentAutoTap',
  props: {
    shopType: {
      type: String,
      required: true
    }
  },
  emits: ["item-clicked"],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const handleItemClicked = item => {
      emit("item-clicked", item);
    };
    const content = ref(null);
    const loading = ref(false);
    const useShopItemsStore = ShopItemsStore();
    const toast = useToast();
    const loadShopItems = async () => {
      loading.value = true;
      const items = await useShopItemsStore.getItemsByType(EtcItemEnum.AUTOTAP);
      loading.value = false;
      content.value = {
        title: "Пакеты Автотапера",
        description: "Пакеты, которые дают временные улучшения автотаппера или улучшают его навсегда",
        items: items
      };
      if (items.length === 0) {
        toast.error("Не удалось загрузить данные.");
      }
    };
    onMounted(() => {
      loadShopItems();
    });
    return (_ctx, _cache) => {
      return content.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(content.value.title), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(content.value.description), 1), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(content.value.items, item => {
        return _openBlock(), _createBlock(ShopItem, {
          "block-type": __props.shopType,
          key: item.id,
          item: item,
          config: content.value.config,
          onItemClicked: handleItemClicked
        }, null, 8, ["block-type", "item", "config"]);
      }), 128))])])) : loading.value ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Загрузка...")) : _createCommentVNode("", true);
    };
  }
});