import { userStore } from "@/stores/userData";
import { ErrorResponse } from "@/types/response";
import { useToast } from "@brackets/vue-toastification";
import axios, {
  AxiosInstance,
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosHeaders,
} from "axios";

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || "http://localhost:8000",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
  },
});

apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const store = userStore();
  const hashValue = store.getHash();

  if (!(config.headers instanceof AxiosHeaders)) {
    config.headers = new AxiosHeaders(config.headers);
  }

  config.headers.set("Authorization", hashValue);
  return config;
});

export const handleError = (error: AxiosError<ErrorResponse>): never => {
  const toast = useToast();

  if (error.response) {
    const errorMessage =
      error.response.data.data.error_message ||
      error.response.data.data.error_desc ||
      "Произошла ошибка.";
    toast.error(errorMessage);
  } else if (error.request) {
    toast.error("Нет ответа от сервера.");
  } else {
    toast.error("Ошибка при настройке запроса.");
  }

  throw error;
};

export const get = async <T>(url: string, config = {}): Promise<T> => {
  try {
    const response = await apiClient.get<T>(url, config);
    return response.data;
  } catch (error) {
    return handleError(error as AxiosError<ErrorResponse>);
  }
};

export const post = async <T>(
  url: string,
  data?: any,
  config = {}
): Promise<T> => {
  try {
    const response = await apiClient.post<T>(url, data, config);
    return response.data;
  } catch (error) {
    return handleError(error as AxiosError<ErrorResponse>);
  }
};

export const put = async <T>(
  url: string,
  data?: any,
  config = {}
): Promise<T> => {
  try {
    const response = await apiClient.put<T>(url, data, config);
    return response.data;
  } catch (error) {
    return handleError(error as AxiosError<ErrorResponse>);
  }
};

export const del = async <T>(url: string, config = {}): Promise<T> => {
  try {
    const response = await apiClient.delete<T>(url, config);
    return response.data;
  } catch (error) {
    return handleError(error as AxiosError<ErrorResponse>);
  }
};

export default apiClient;
