import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "modal"
};
const _hoisted_2 = {
  class: "modal_content"
};
const _hoisted_3 = ["src", "alt"];
const _hoisted_4 = {
  class: "event_name"
};
const _hoisted_5 = {
  key: 0,
  class: "event_rating"
};
const _hoisted_6 = {
  key: 1,
  class: "event_next_level"
};
const _hoisted_7 = {
  class: "event_rewards"
};
const _hoisted_8 = {
  class: "event_reward_title"
};
const _hoisted_9 = {
  class: "event_reward_items"
};
const _hoisted_10 = ["src", "alt"];
const _hoisted_11 = {
  class: "event_history"
};
const _hoisted_12 = {
  class: "modal_action"
};
import { onMounted, onBeforeUnmount, watch, computed } from "vue";
import { Utils } from "@/assets/js/utils";
import ButtonMain from "../Libs/ButtonMain.vue";
export default /*@__PURE__*/_defineComponent({
  __name: 'RaidBossModal',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    eventType: {
      type: Object,
      required: true
    }
  },
  emits: ["close", "enter-instance"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const TextButton = computed(() => {
      let now = new Date().getTime();
      if (props.eventType.status) {
        return `Войти в инстанс`;
      }
      return `Доступ откроется через ${Utils.formatRemainingTimeEvent(props.eventType.start, now)}`;
    });
    const emitClick = __emit;
    const closeModal = () => {
      emitClick("close");
    };
    const handleEsc = event => {
      if (event.key === "Escape") {
        closeModal();
      }
    };
    onMounted(() => {
      window.addEventListener("keydown", handleEsc);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("keydown", handleEsc);
      document.body.style.overflow = "";
    });
    watch(() => props.isVisible, newVal => {
      if (newVal) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    }, {
      immediate: true
    });
    const enterInstance = () => {
      closeModal();
      emitClick("enter-instance", props.eventType);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_Transition, {
        name: "fade"
      }, {
        default: _withCtx(() => [__props.isVisible ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
          class: "modal-gradient",
          onClick: closeModal
        }), _cache[1] || (_cache[1] = _createElementVNode("div", {
          class: "modal-container_top_border"
        }, null, -1)), _createElementVNode("div", {
          class: "modal-container",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
          class: "modal_content_close",
          onClick: closeModal
        }), _createElementVNode("img", {
          src: __props.eventType.src,
          alt: __props.eventType.name,
          class: "event_img"
        }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, _toDisplayString(__props.eventType.name), 1), __props.eventType.status ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Ваш рейтинг: Топ 5% ")) : _createCommentVNode("", true), __props.eventType.status ? (_openBlock(), _createElementBlock("div", _hoisted_6, " До следующего уровня осталось: 8 675 222 урона ")) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.eventType.rewardGroups, reward => {
          return _openBlock(), _createElementBlock("div", {
            class: "event_reward",
            key: reward.id,
            style: _normalizeStyle({
              background: `rgba(${_unref(Utils).hexToRgb(reward.color)}, 0.05)`,
              boxShadow: `0 0 20px 3px ${reward.color}`,
              borderColor: reward.color
            })
          }, [_createElementVNode("div", _hoisted_8, _toDisplayString(reward.name), 1), _createElementVNode("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reward.rewards, item => {
            return _openBlock(), _createElementBlock("div", {
              class: "event_reward_item",
              key: item.id
            }, [_createElementVNode("img", {
              src: item.src,
              alt: item.name
            }, null, 8, _hoisted_10), _createTextVNode(" " + _toDisplayString(item.name), 1)]);
          }), 128))])], 4);
        }), 128))]), _createElementVNode("div", _hoisted_11, _toDisplayString(__props.eventType.history), 1)]), _createElementVNode("div", _hoisted_12, [_createVNode(ButtonMain, {
          text: TextButton.value,
          onClick: enterInstance,
          disabled: !__props.eventType.status
        }, null, 8, ["text", "disabled"])])])])) : _createCommentVNode("", true)]),
        _: 1
      });
    };
  }
});