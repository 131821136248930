import { userStore } from "@/stores/userData";

export class Index {
  userData: any;

  init() {
    this.userData = userStore();
    this.initTg();
  }

  initTg() {
    const tg = window.Telegram?.WebApp;
    console.log(tg);

    if (tg) {
      let userData;
      tg.ready();

      if (process.env.VUE_APP_PRODUCTION === "True") {
        userData = tg.initData;
      } else {
        userData = process.env.VUE_APP_TG_DATA;
      }
      if (userData) {
        this.userData.initUser(userData);
      }

      document.getElementById("close-btn")?.addEventListener("click", () => {
        tg.close();
      });
    }
  }
}
