import "core-js/modules/es.array.push.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '@/assets/images/icons/roulette_pin.svg';
import _imports_1 from '@/assets/images/ticket_roulette.png';
const _hoisted_1 = {
  key: 0,
  class: "shop_section"
};
const _hoisted_2 = {
  class: "shop_title"
};
const _hoisted_3 = {
  class: "shop_description"
};
const _hoisted_4 = {
  class: "shop_content-wrapper"
};
const _hoisted_5 = {
  class: "roulette"
};
const _hoisted_6 = ["dataidhash"];
const _hoisted_7 = ["src", "alt"];
const _hoisted_8 = {
  class: "roulette_ticket"
};
const _hoisted_9 = {
  class: "roulette_chance"
};
const _hoisted_10 = {
  class: "roulette_chance_list"
};
const _hoisted_11 = {
  class: "overlay"
};
const _hoisted_12 = {
  class: "animated_case"
};
const _hoisted_13 = ["src", "alt"];
const _hoisted_14 = {
  class: "additional_elements"
};
const _hoisted_15 = {
  key: 1
};
const _hoisted_16 = {
  key: 2
};
import { onMounted, ref, nextTick, computed } from "vue";
import { CreepingLine } from "@/assets/js/CreepingLine.js";
import ButtonMain from "@/components/Libs/ButtonMain.vue";
import CaseChanceInfo from "../Cases/CaseChanceInfo.vue";
const caseCount = 50;
export default /*@__PURE__*/_defineComponent({
  __name: 'ContentCases',
  props: {
    shopType: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const isWinnerAnimation = ref(false);
    const tickets = ref(200);
    const isSpinning = ref(false);
    const winningCase = ref(null);
    const winningPresentInCase = ref(null);
    const content = ref(null);
    const loading = ref(false);
    const error = ref(null);
    const targetPosition = ref(0);
    const caseTypes = [{
      type: "Bronze",
      chance: 50,
      src: require("@/assets/images/bronze_case.png")
    }, {
      type: "Silver",
      chance: 30,
      src: require("@/assets/images/silver_case.png")
    }, {
      type: "Gold",
      chance: 15,
      src: require("@/assets/images/gold_case.png")
    }, {
      type: "Platinum",
      chance: 4,
      src: require("@/assets/images/silver_case.png")
    }, {
      type: "Diamond",
      chance: 1,
      src: require("@/assets/images/diamond_case.png")
    }];
    const caseInfo = [{
      type: "bronze",
      chance: 50,
      title: "Бронзовый кейс",
      color: "#CD7F32",
      items: [{
        icon: require("@/assets/images/icons/moneta.svg"),
        text: "50,000 монет",
        chance: 40
      }, {
        icon: require("@/assets/images/icons/boost.svg"),
        text: "Бустер х2 на 30 минут",
        chance: 25
      }, {
        icon: require("@/assets/images/icons/autotap.svg"),
        text: "Автотап на 1 час",
        chance: 20
      }, {
        icon: require("@/assets/images/icons/vpn.svg"),
        text: "1 час VPN-доступа",
        chance: 15
      }]
    }, {
      type: "silver",
      chance: 30,
      title: "Серебряный кейс",
      color: "#C0C0C0",
      items: [{
        icon: require("@/assets/images/icons/moneta.svg"),
        text: "100,000 монет",
        chance: 40
      }, {
        icon: require("@/assets/images/icons/boost.svg"),
        text: "Бустер х3 на 30 минут",
        chance: 25
      }, {
        icon: require("@/assets/images/icons/autotap.svg"),
        text: "Автотап на 3 часа",
        chance: 20
      }, {
        icon: require("@/assets/images/icons/vpn.svg"),
        text: "3 часа VPN-доступа",
        chance: 15
      }]
    }, {
      type: "gold",
      chance: 30,
      title: "Золотой кейс",
      color: "#FFD700",
      items: [{
        icon: require("@/assets/images/icons/moneta.svg"),
        text: "500,000 монет",
        chance: 40
      }, {
        icon: require("@/assets/images/icons/boost.svg"),
        text: "Бустер х4 на 30 минут",
        chance: 25
      }, {
        icon: require("@/assets/images/icons/autotap.svg"),
        text: "Перманентное улучшение +1 тап/сек",
        chance: 20
      }, {
        icon: require("@/assets/images/icons/vpn.svg"),
        text: "12 часов VPN-доступа",
        chance: 15
      }]
    }, {
      type: "platinum",
      chance: 30,
      title: "Платиновый кейс",
      color: "#FAF9E4",
      items: [{
        icon: require("@/assets/images/icons/moneta.svg"),
        text: "1,000,000 монет",
        chance: 40
      }, {
        icon: require("@/assets/images/icons/boost.svg"),
        text: "Бустер х5 на 1 час",
        chance: 25
      }, {
        icon: require("@/assets/images/icons/autotap.svg"),
        text: "Перманентное улучшение +2 тап/сек",
        chance: 20
      }, {
        icon: require("@/assets/images/icons/vpn.svg"),
        text: "1 день VPN-доступа",
        chance: 15
      }]
    }, {
      type: "diamond",
      chance: 30,
      title: "Алмазный кейс",
      color: "#8E44AD",
      items: [{
        icon: require("@/assets/images/icons/moneta.svg"),
        text: "5,000,000 монет",
        chance: 40
      }, {
        icon: require("@/assets/images/icons/boost.svg"),
        text: "Бустер х5 на 2 часа",
        chance: 25
      }, {
        icon: require("@/assets/images/icons/autotap.svg"),
        text: "Перманентное улучшение +3 тап/сек",
        chance: 20
      }, {
        icon: require("@/assets/images/icons/vpn.svg"),
        text: "7 дней VPN-доступа",
        chance: 15
      }]
    }];
    const spinRoulette = async () => {
      if (isSpinning.value || tickets.value <= 0) return;
      await getWinningCase();
      if (!winningCase.value) return;
      tickets.value -= 1;
      console.log("Выигрышный кейс:", winningCase.value.id, winningCase.value.typeCase);
      let caseWinner = document.querySelector(`[dataidhash = '${winningCase.value.id}']`);
      const rect = caseWinner.getBoundingClientRect();
      const parentRect = caseWinner.closest(".roulette").getBoundingClientRect();
      const itemX = rect.left - parentRect.left;
      const parentWidth = parentRect.width;
      const itemWidth = caseWinner.offsetWidth;
      const parentCenterX = parentWidth / 2;
      const shiftX = parentCenterX - itemX - itemWidth / 2;
      const roundedShiftX = Math.round(shiftX);
      console.log("roundedShiftX", roundedShiftX);
      isSpinning.value = true;
      targetPosition.value = roundedShiftX;
    };
    const handleSpinEnd = () => {
      isSpinning.value = false;
      isWinnerAnimation.value = true;
      nextTick(() => {
        cases.value = generateCases(caseCount);
      });
    };
    const rouletteStyle = computed(() => {
      if (!isSpinning.value) {
        return {
          animation: "none",
          transform: "translateX(0)"
        };
      }
      return {
        animation: `spin ${5}s cubic-bezier(0.33, 1, 0.68, 1) forwards`,
        "--target-translate-x": `${targetPosition.value}px`
      };
    });
    const generatedIds = new Set();
    const getRandomCase = () => {
      const totalChance = caseTypes.reduce((sum, caseType) => sum + caseType.chance, 0);
      const randomValue = Math.random() * totalChance;
      let cumulativeChance = 0;
      for (const caseType of caseTypes) {
        cumulativeChance += caseType.chance;
        if (randomValue <= cumulativeChance) {
          let idHash;
          do {
            idHash = Math.random().toString(36).substring(2, 9);
          } while (generatedIds.has(idHash));
          generatedIds.add(idHash);
          return {
            typeCase: caseType.type,
            chance: caseType.chance,
            src: caseType.src,
            id: idHash
          };
        }
      }
      return {
        typeCase: caseTypes[0].type,
        chance: caseTypes[0].chance,
        src: caseTypes[0].src,
        id: Math.random().toString(36).substring(2, 9)
      };
    };
    const generateCases = count => {
      const cases = [];
      generatedIds.clear();
      for (let i = 0; i < count; i++) {
        cases.push(getRandomCase());
      }
      return cases;
    };
    const cases = ref(generateCases(caseCount));
    const loadShopItems = async () => {
      loading.value = true;
      error.value = null;
      try {
        content.value = {
          title: "Рулетка с кейсами",
          description: "Испытай удачу и выиграй невероятные награды!",
          items: []
        };
      } catch (err) {
        error.value = "Не удалось загрузить данные.";
        console.error(err);
      } finally {
        loading.value = false;
      }
    };
    onMounted(() => {
      loadShopItems();
      nextTick(() => {
        const creepingLine = new CreepingLine(".roulette_creeping_line");
        creepingLine.changeText(['Игрок mitfleg выбил <span class="color_gold">золотой кейс</span> 10 минут назад и получил Super VIP!', 'Игрок mitfleg выбил <span class="color_gold">золотой кейс</span> 15 минут назад и получил Буст х4 на 30 минут!', "Запускайте рулетку и выигрывайте ценные призы!"]);
        creepingLine.start();
      });
    });
    const closeWinnerAnimation = () => {
      isWinnerAnimation.value = false;
      winningCase.value = null;
    };
    const getWinningCase = async () => {
      const randomIndex = 20 + Math.floor(Math.random() * (cases.value.length - 20));
      const randomCase = cases.value[randomIndex];
      winningCase.value = randomCase;
      winningPresentInCase.value = {
        id: 1,
        caseId: randomCase.id,
        name: "50,000 монет",
        chance: 40,
        src: require("@/assets/images/icons/moneta.svg")
      };
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [content.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(content.value.title), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(content.value.description), 1), _createElementVNode("div", _hoisted_4, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "roulette_creeping_line"
      }, [_createElementVNode("div", {
        class: "text"
      })], -1)), _createElementVNode("div", _hoisted_5, [_cache[0] || (_cache[0] = _createElementVNode("img", {
        class: "roulette_pin",
        src: _imports_0
      }, null, -1)), _createElementVNode("div", {
        class: _normalizeClass(["roulette_wheel", {
          spinning: isSpinning.value
        }]),
        style: _normalizeStyle(rouletteStyle.value),
        onAnimationend: handleSpinEnd
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cases.value, caseItem => {
        return _openBlock(), _createElementBlock("div", {
          class: "case_item",
          key: caseItem.id,
          dataidhash: caseItem.id
        }, [_createElementVNode("img", {
          src: caseItem.src,
          alt: caseItem.typeCase
        }, null, 8, _hoisted_7)], 8, _hoisted_6);
      }), 128))], 38)]), _createElementVNode("div", _hoisted_8, [_createTextVNode(" Осталось билетов: " + _toDisplayString(tickets.value) + " ", 1), _cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1))]), _createVNode(ButtonMain, {
        text: "Крутить",
        onClick: spinRoulette,
        disabled: isSpinning.value || tickets.value <= 0
      }, null, 8, ["disabled"]), _createElementVNode("div", _hoisted_9, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "roulette_chance_text"
      }, "Шансы и призы", -1)), _createElementVNode("div", _hoisted_10, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(caseInfo, caseItem => {
        return _createVNode(CaseChanceInfo, {
          key: caseItem.type,
          caseInfo: caseItem
        }, null, 8, ["caseInfo"]);
      }), 64))])])]), isWinnerAnimation.value && winningCase.value ? (_openBlock(), _createBlock(_Transition, {
        key: 0,
        name: "fade"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_11, [_cache[5] || (_cache[5] = _createElementVNode("div", {
          class: "backdrop"
        }, null, -1)), _createElementVNode("div", _hoisted_12, [_createElementVNode("img", {
          src: winningCase.value?.src,
          alt: winningCase.value?.typeCase
        }, null, 8, _hoisted_13)]), _createElementVNode("div", _hoisted_14, [_cache[4] || (_cache[4] = _createElementVNode("h2", null, "Поздравляем! Вы выиграли!", -1)), _createTextVNode(" " + _toDisplayString(winningPresentInCase.value?.name) + " ", 1), _createVNode(ButtonMain, {
          text: "Закрыть",
          onClick: closeWinnerAnimation
        })])])]),
        _: 1
      })) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_15, "Загрузка...")), error.value ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(error.value), 1)) : _createCommentVNode("", true)], 64);
    };
  }
});