import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "shop"
};
import { computed, ref, watch, onMounted } from "vue";
import ShopMenu from "./ShopMenu.vue";
import ShopSection from "./ShopSection.vue";
export default /*@__PURE__*/_defineComponent({
  __name: 'ShopMain',
  props: {
    shopType: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const activeSection = ref("coins");
    onMounted(() => {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const params = new URLSearchParams(url.search);
      const activeSectionInURL = params.get("activeSection");
      if (activeSectionInURL) {
        activeSection.value = activeSectionInURL;
      }
    });
    const menuItems = computed(() => {
      let menu = [{
        section: "coins",
        title: "Монеты",
        active: false
      }, {
        section: "vip",
        title: "VIP",
        active: false
      }, {
        section: "boosts",
        title: "Бусты",
        active: false
      }, {
        section: "auto_tap",
        title: "Автотап",
        active: false
      }, {
        section: "exclusive",
        title: "Эксклюзив",
        active: false
      },
      // { section: "special", title: "Особые пакеты", active: false },
      {
        section: "cases",
        title: "Кейсы",
        active: false
      }];
      if (props.shopType === "boosts") {
        menu = menu.filter(item => item.section !== "coins");
      }
      return menu.map(item => ({
        ...item,
        active: item.section === activeSection.value
      }));
    });
    // Дополнительный Watcher на menuItems для проверки валидности activeSection
    watch(menuItems, newMenu => {
      const isActiveSectionValid = newMenu.some(item => item.section === activeSection.value);
      if (!isActiveSectionValid) {
        // Устанавливаем активной первой доступной секции
        activeSection.value = newMenu.length > 0 ? newMenu[0].section : "";
      }
    }, {
      immediate: true
    });
    const changeSection = section => {
      activeSection.value = section;
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(ShopMenu, {
        menuItems: menuItems.value,
        onChangeSection: changeSection
      }, null, 8, ["menuItems"]), _createVNode(ShopSection, {
        shopType: __props.shopType,
        activeSection: activeSection.value
      }, null, 8, ["shopType", "activeSection"])]);
    };
  }
});