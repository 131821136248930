import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../assets/images/icons/button.svg';
const _hoisted_1 = {
  class: "page"
};
const _hoisted_2 = {
  class: "info"
};
const _hoisted_3 = {
  class: "info_user"
};
const _hoisted_4 = {
  class: "info_user_name"
};
const _hoisted_5 = {
  class: "container"
};
const _hoisted_6 = {
  class: "boosts"
};
import MainMenu from "./Menu.vue";
import CurrentState from "./CurrentState.vue";
import { Main } from "@/assets/js/main";
import { RouterLink } from "vue-router";
import { onMounted } from "vue";
import { userStore } from "@/stores/userData";
export default /*@__PURE__*/_defineComponent({
  __name: 'MainPage',
  setup(__props) {
    const userData = userStore();
    onMounted(() => {
      new Main(require("@/assets/images/icons/moneta.svg"));
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_cache[7] || (_cache[7] = _createElementVNode("div", {
        class: "gradient"
      }, null, -1)), _cache[8] || (_cache[8] = _createElementVNode("div", {
        class: "app_name"
      }, "XyRen Game", -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "info_user_icon"
      }, null, -1)), _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(userData).user.username || _unref(userData).user.first_name || _unref(userData).user.chat_id), 1), _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "info_user_vip",
        "data-vip": "super"
      }, null, -1))]), _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "info_status"
      }, [_createElementVNode("div", {
        class: "info_status_text"
      }, "Статус VPN"), _createElementVNode("div", {
        class: "info_status_status",
        "data-have-vpn": "false"
      })], -1))]), _createElementVNode("div", _hoisted_5, [_createVNode(CurrentState), _createVNode(_unref(RouterLink), {
        to: "/events",
        class: "events_btn"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Ивенты")])),
        _: 1
      }), _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"level\" data-v-5b09c029><div class=\"level_info\" data-v-5b09c029><div class=\"level_info-status\" data-v-5b09c029>Начинающий &gt;</div><div class=\"level_info-level\" data-v-5b09c029><div class=\"level_info-level-text\" data-v-5b09c029>Уровень</div><div class=\"level_info-level-value\" data-v-5b09c029>6/10</div></div></div><div class=\"level_progress\" data-v-5b09c029><div class=\"level_progress-bar\" style=\"width:75%;\" data-v-5b09c029></div></div></div><div class=\"button\" data-v-5b09c029><img src=\"" + _imports_0 + "\" alt=\"\" data-v-5b09c029></div>", 2)), _createElementVNode("div", _hoisted_6, [_cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "boosts_stat"
      }, [_createElementVNode("div", {
        class: "boosts_stat-icon"
      }), _createElementVNode("div", {
        class: "boosts_stat-text"
      }, "x2 / 05:00")], -1)), _createVNode(_unref(RouterLink), {
        to: "/boosts?activeSection=boosts",
        class: "boosts_name"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("Усилитель>")])),
        _: 1
      })])])]), _createVNode(MainMenu)], 64);
    };
  }
});