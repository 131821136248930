import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "page"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "event_list"
};
import EventStat from "./EventStat.vue";
import { onMounted, ref } from "vue";
import RaidBossItem from "./RaidBossItem.vue";
import MainMenu from "@/components/Menu.vue";
import EventMenu from "./EventMenu.vue";
import RaidBossModal from "./RaidBossModal.vue";
export default /*@__PURE__*/_defineComponent({
  __name: 'EventPage',
  setup(__props) {
    const events = ref([]);
    const eventMenu = ref([]);
    const isVisibleModal = ref(false);
    const selectEvent = ref(null);
    onMounted(() => {
      loadEventMenu();
      loadEvents();
    });
    const loadEventMenu = async () => {
      eventMenu.value = [{
        section: "raid_bosses",
        title: "Рейд боссы",
        active: true
      }, {
        section: "event1",
        title: "Ивент",
        active: false
      }, {
        section: "event2",
        title: "Ивент",
        active: false
      }, {
        section: "event3",
        title: "Ивент",
        active: false
      }, {
        section: "event4",
        title: "Ивент",
        active: false
      }];
    };
    const loadEvents = async () => {
      events.value = [{
        id: 1,
        name: "Titan Fraudix",
        src: require("@/assets/images/Titan_Fraudix.png"),
        start: new Date("2024-12-17 16:00").getTime(),
        end: new Date("2024-12-18 16:00").getTime(),
        color: "#93DB6A",
        status: true,
        history: "Titan Fraudix был когда-то гениальным программистом и мастером по шифрованию данных. Он создал сложные системы защиты, чтобы защищать пользователей от злонамеренных вторжений в их личные данные. Но со временем его сердце помрачнело жадностью. Fraudix понял, что может использовать свои знания для контроля над потоками данных и личной информацией, заставляя пользователей платить за свободу и безопасность.\n\nТеперь он стремится монополизировать все ресурсы VPN, установив полный контроль над анонимностью пользователей в интернете. Fraudix верит, что только он имеет право управлять доступом к сети, и любой, кто пытается оспорить его власть, должен быть подавлен. Именно поэтому он стал злым героем, представляющим жадность и контроль.",
        rewardGroups: [{
          id: 1,
          name: "Топ 1% (Элита битвы)",
          color: "#FFD700",
          rewards: [{
            id: 1,
            name: "5,000,000 Монеты",
            src: require("@/assets/images/icons/moneta.svg"),
            count: 5000000
          }, {
            id: 2,
            name: "Super VIP на 14 дней",
            src: require("@/assets/images/icons/vip_super.svg"),
            count: 1
          }, {
            id: 3,
            name: "Легендарный Амулет Защиты",
            src: require("@/assets/images/icons/amulet.svg"),
            count: 1
          }, {
            id: 4,
            name: "Билет в рулетку",
            src: require("@/assets/images/ticket_roulette.png"),
            count: 2
          }, {
            id: 5,
            name: "x5 на 1 час",
            src: require("@/assets/images/icons/boost.svg"),
            count: 3
          }]
        }, {
          id: 2,
          name: "Топ 5% (Мастера атаки)",
          color: "#8E44AD",
          rewards: [{
            id: 1,
            name: "3,000,000 Монеты",
            src: require("@/assets/images/icons/moneta.svg"),
            count: 3000000
          }, {
            id: 2,
            name: "VIP Gold на 10 дней",
            src: require("@/assets/images/icons/vip_gold.svg"),
            count: 1
          }, {
            id: 3,
            name: "Амулет Война 1 шт",
            src: require("@/assets/images/icons/amulet.svg"),
            count: 1
          }, {
            id: 4,
            name: "Билет в рулетку",
            src: require("@/assets/images/ticket_roulette.png"),
            count: 1
          }, {
            id: 5,
            name: "x4 на 1 час 2 шт",
            src: require("@/assets/images/icons/boost.svg"),
            count: 2
          }]
        }, {
          id: 3,
          name: "Топ 10% (Покорители системы)",
          color: "#4A90E2",
          rewards: [{
            id: 1,
            name: "1,500,000 монет",
            src: require("@/assets/images/icons/moneta.svg"),
            count: 1500000
          }, {
            id: 2,
            name: "VIP Silver на 7 дней",
            src: require("@/assets/images/icons/vip_silver.svg"),
            count: 1
          }, {
            id: 3,
            name: "Амулет Удачи 1 шт",
            src: require("@/assets/images/icons/amulet.svg"),
            count: 1
          }, {
            id: 5,
            name: "x3 на 30 минут 1 шт",
            src: require("@/assets/images/icons/boost.svg"),
            count: 1
          }]
        }, {
          id: 4,
          name: "Топ 20% (Воины цифрового фронта)",
          color: "#27AE60",
          rewards: [{
            id: 1,
            name: "800,000 монет",
            src: require("@/assets/images/icons/moneta.svg"),
            count: 800000
          }, {
            id: 2,
            name: "VIP Bronze на 4 днz",
            src: require("@/assets/images/icons/vip_bronze.svg"),
            count: 1
          }, {
            id: 5,
            name: "x2 на 15 минут 1 шт",
            src: require("@/assets/images/icons/boost.svg"),
            count: 1
          }]
        }, {
          id: 5,
          name: "Топ 50% (Активные участники)",
          color: "#B0B0B0",
          rewards: [{
            id: 1,
            name: "400,000 монет",
            src: require("@/assets/images/icons/moneta.svg"),
            count: 400000
          }, {
            id: 5,
            name: "x2 на 10 минут 1 шт",
            src: require("@/assets/images/icons/boost.svg"),
            count: 1
          }]
        }, {
          id: 6,
          name: "Участники битвы",
          color: "#7F8C8D",
          rewards: [{
            id: 1,
            name: "150,000 монет",
            src: require("@/assets/images/icons/moneta.svg"),
            count: 150000
          }, {
            id: 5,
            name: "x2 на 5 минут 1 шт",
            src: require("@/assets/images/icons/boost.svg"),
            count: 1
          }]
        }]
      }, {
        id: 2,
        name: "Coinstalker",
        src: require("@/assets/images/Coinstalker.png"),
        start: new Date("2025-01-12 21:00").getTime(),
        end: new Date("2025-01-14 21:00").getTime(),
        color: "#4A90E2",
        status: false,
        history: 'Coinstalker — это дух, рожденный из всех монет, которые были потеряны или потрачены впустую за века. Он существовал как призрак, скрытый в тенях, до тех пор, пока накопление мощи не сделало его могущественным существом. Теперь Coinstalker охотится на игроков, крадет их накопленные монеты и использует их для укрепления своей темной силы.\n\nCoinstalker стал воплощением ненасытной алчности. Он стремится собрать как можно больше монет и создать свое "королевство золота", где каждый будет вынужден ему подчиняться. Игроки должны побеждать его, чтобы вернуть свои ресурсы и предотвратить его дальнейшее доминирование.',
        rewardGroups: [{
          id: 1,
          name: "Топ 1% (Элита битвы)",
          color: "#FFD700",
          rewards: [{
            id: 1,
            name: "5,000,000 Монеты",
            src: require("@/assets/images/icons/moneta.svg"),
            count: 5000000
          }, {
            id: 2,
            name: "Super VIP",
            src: require("@/assets/images/icons/vip_super.svg"),
            count: 1
          }, {
            id: 3,
            name: "Легендарный Амулет Защиты",
            src: require("@/assets/images/icons/amulet.svg"),
            count: 1
          }, {
            id: 4,
            name: "Билет в рулетку",
            src: require("@/assets/images/ticket_roulette.png"),
            count: 2
          }, {
            id: 5,
            name: "x5 на 1 час",
            src: require("@/assets/images/icons/boost.svg"),
            count: 3
          }]
        }]
      }, {
        id: 3,
        name: "Lady Chronos",
        src: require("@/assets/images/Lady_Chronos.png"),
        start: new Date("2025-01-14 16:00").getTime(),
        end: new Date("2025-01-16 16:00").getTime(),
        color: "#8E44AD",
        status: false,
        history: "Lady Chronos была хранительницей времени и пространства, задачей которой было поддерживать баланс между миром игры и реальностью. Но однажды, столкнувшись с нарушением временных границ, она решила, что игроки злоупотребляют своим временем, пытаясь изменить судьбу.\n\nLady Chronos превратилась в деспотичного хранителя времени, желающего установить свой порядок, при котором только она сможет решать, как и кто может использовать время. Она наложила временные проклятия на бустеры и автотаперы, делая их недоступными, и заставляя игроков медленно, но верно терять все заработанное. Ее цель — вернуть контроль над каждым мигом, который игроки тратят в игре.",
        rewardGroups: [{
          id: 1,
          name: "Топ 1% (Элита битвы)",
          color: "#FFD700",
          rewards: [{
            id: 1,
            name: "5,000,000 Монеты",
            src: require("@/assets/images/icons/moneta.svg"),
            count: 5000000
          }, {
            id: 2,
            name: "Super VIP",
            src: require("@/assets/images/icons/vip_super.svg"),
            count: 1
          }, {
            id: 3,
            name: "Легендарный Амулет Защиты",
            src: require("@/assets/images/icons/amulet.svg"),
            count: 1
          }, {
            id: 4,
            name: "Билет в рулетку",
            src: require("@/assets/images/ticket_roulette.png"),
            count: 2
          }, {
            id: 5,
            name: "x5 на 1 час",
            src: require("@/assets/images/icons/boost.svg"),
            count: 3
          }]
        }]
      }, {
        id: 4,
        name: "Firewall Warden",
        src: require("@/assets/images/Firewall_Warden.png"),
        start: new Date("2025-01-16 21:00").getTime(),
        end: new Date("2025-01-18 21:00").getTime(),
        color: "#FFD700",
        status: false,
        history: "История: Firewall Warden — это могущественное создание, возникшее из всех заблокированных и цензурируемых данных, которые когда-либо существовали. Он состоит из магических цифровых оград и стен, созданных, чтобы сдерживать свободный обмен информацией. Когда-то его задача была защищать данные и обеспечивать их безопасность, но после многих лет обиды и забвения он решил захватить власть и заблокировать доступ ко всем ресурсам.\n\nFirewall Warden видит в свободном использовании VPN угрозу своему могуществу и стремится уничтожить все, что помогает пользователям обходить ограничения. Он строит новые и более сложные ограждения, пытаясь сдержать поток информации и ограничить игроков в их правах на свободное использование сети.",
        rewardGroups: [{
          id: 1,
          name: "Топ 1% (Элита битвы)",
          color: "#FFD700",
          rewards: [{
            id: 1,
            name: "5,000,000 Монеты",
            src: require("@/assets/images/icons/moneta.svg"),
            count: 5000000
          }, {
            id: 2,
            name: "Super VIP",
            src: require("@/assets/images/icons/vip_super.svg"),
            count: 1
          }, {
            id: 3,
            name: "Легендарный Амулет Защиты",
            src: require("@/assets/images/icons/amulet.svg"),
            count: 1
          }, {
            id: 4,
            name: "Билет в рулетку",
            src: require("@/assets/images/ticket_roulette.png"),
            count: 2
          }, {
            id: 5,
            name: "x5 на 1 час",
            src: require("@/assets/images/icons/boost.svg"),
            count: 3
          }]
        }]
      }];
    };
    const closeModal = () => {
      isVisibleModal.value = false;
    };
    const openInfo = event => {
      isVisibleModal.value = true;
      selectEvent.value = event;
    };
    const enterInstance = event => {
      console.log("enterInstance", event.name);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "gradient"
      }, null, -1)), _createElementVNode("div", _hoisted_2, [_createVNode(EventStat, {
        events: events.value
      }, null, 8, ["events"]), _cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "events_title"
      }, "Список ивентов", -1)), _createVNode(EventMenu, {
        menuItems: eventMenu.value
      }, null, 8, ["menuItems"]), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(events.value, event => {
        return _openBlock(), _createBlock(RaidBossItem, {
          key: event.id,
          event: event,
          onClick: openInfo,
          onEnterInstance: enterInstance
        }, null, 8, ["event"]);
      }), 128))])])]), selectEvent.value ? (_openBlock(), _createBlock(RaidBossModal, {
        key: 0,
        isVisible: isVisibleModal.value,
        eventType: selectEvent.value,
        onClose: closeModal,
        onEnterInstance: enterInstance
      }, null, 8, ["isVisible", "eventType"])) : _createCommentVNode("", true), _createVNode(MainMenu)], 64);
    };
  }
});