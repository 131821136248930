import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "current_state_wrapper"
};
const _hoisted_2 = {
  class: "current_state"
};
const _hoisted_3 = {
  key: 0,
  class: "current_state-item"
};
const _hoisted_4 = {
  class: "current_state-tap-value"
};
const _hoisted_5 = {
  key: 1,
  class: "current_state-item"
};
const _hoisted_6 = {
  key: 2,
  class: "current_state-item"
};
const _hoisted_7 = {
  class: "current_state-level-value"
};
const _hoisted_8 = {
  key: 3,
  class: "current_state-item"
};
import { ref, computed, onMounted, onUnmounted } from "vue";
import { Utils } from "@/assets/js/utils";
export default /*@__PURE__*/_defineComponent({
  __name: 'EventStat',
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    const props = __props;
    const now = ref(Date.now());
    let timer;
    onMounted(() => {
      timer = window.setInterval(() => {
        now.value = Date.now();
      }, 60 * 1000);
    });
    onUnmounted(() => {
      clearInterval(timer);
    });
    const currentEvent = computed(() => {
      const activeEvents = props.events.filter(event => event.start <= now.value && event.end > now.value);
      if (activeEvents.length === 0) return null;
      return activeEvents.sort((a, b) => a.end - b.end)[0];
    });
    const nextEvent = computed(() => {
      const upcomingEvents = props.events.filter(event => event.start > now.value);
      if (upcomingEvents.length === 0) return null;
      return upcomingEvents.sort((a, b) => a.start - b.start)[0];
    });
    const formattedCurrentEventEnd = computed(() => {
      if (!currentEvent.value) return "Нет активных событий";
      return `До окончания ${Utils.formatRemainingTimeEvent(currentEvent.value.end, now.value)}`;
    });
    const formattedNextEventStart = computed(() => {
      if (!nextEvent.value) return "Нет событий";
      return `Через ${Utils.formatRemainingTimeEvent(nextEvent.value.start, now.value)}`;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [currentEvent.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "current_state-tap-text"
      }, "Текущий ивент", -1)), _createElementVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString(currentEvent.value.name), 1), _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)), _createTextVNode(" " + _toDisplayString(formattedCurrentEventEnd.value), 1)])])) : (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[2] || (_cache[2] = [_createElementVNode("div", {
        class: "current_state-tap-text"
      }, "Текущий ивент", -1), _createElementVNode("div", {
        class: "current_state-tap-value"
      }, "Нет активных событий", -1)]))), nextEvent.value ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "current_state-level-text"
      }, "Следующий ивент", -1)), _createElementVNode("div", _hoisted_7, [_createTextVNode(_toDisplayString(nextEvent.value.name), 1), _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)), _createTextVNode(" " + _toDisplayString(formattedNextEventStart.value), 1)])])) : (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[5] || (_cache[5] = [_createElementVNode("div", {
        class: "current_state-level-text"
      }, "Следующий ивент", -1), _createElementVNode("div", {
        class: "current_state-level-value"
      }, "Нет предстоящих событий", -1)])))])]);
    };
  }
});