import { getItemsByType } from "@/api/etcItemsApi";
import { EtcItemEnum } from "@/enums/EtcItemEnum";
import { ShopItem, StoreItems } from "@/types/shopTypes";
import { defineStore } from "pinia";

export const ShopItemsStore = defineStore("ShopItemsStore", {
  state: () => ({
    items: {} as StoreItems,
    isLoading: {} as Record<EtcItemEnum, boolean>,
    error: {} as Record<EtcItemEnum, string | null>,
  }),
  actions: {
    async getItemsByType(type: EtcItemEnum): Promise<ShopItem[]> {
      if (this.items[type]) {
        return this.items[type] || [];
      }

      this.isLoading[type] = true;
      this.error[type] = null;

      try {
        this.items[type] = await getItemsByType(type);
      } catch (err) {
        this.error[type] = "Не удалось загрузить данные.";
        console.error(err);
      } finally {
        this.isLoading[type] = false;
      }

      return this.items[type] || [];
    },
  },
});
