import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "current_state_wrapper"
};
const _hoisted_2 = {
  class: "current_state"
};
const _hoisted_3 = {
  class: "current_state-item"
};
const _hoisted_4 = {
  class: "current_state-tap-value"
};
const _hoisted_5 = {
  class: "current_state-item"
};
const _hoisted_6 = {
  class: "current_state-level-text"
};
const _hoisted_7 = {
  class: "current_state-level-value"
};
const _hoisted_8 = {
  class: "balance"
};
const _hoisted_9 = {
  class: "balance_text"
};
import { userStore } from "@/stores/userData";
export default /*@__PURE__*/_defineComponent({
  __name: 'CurrentState',
  setup(__props) {
    const userData = userStore();
    function formatNumber(value) {
      return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    function formatNextLevel(value) {
      let valueString;
      if (value) {
        if (value >= 1000 && value < 1000000) {
          valueString = `${(value / 1000).toFixed(1)}K`;
        } else if (value >= 1000000) {
          valueString = `${(value / 1000000).toFixed(1)}M`;
        } else {
          valueString = String(value);
        }
      } else {
        valueString = "Max";
      }
      return valueString;
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "current_state-tap-text"
      }, "Монет за нажатие", -1)), _createElementVNode("div", _hoisted_4, " +" + _toDisplayString(formatNumber(_unref(userData).user.coin_per_tap)), 1)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(_unref(userData).user.coin_next_level ? "Следующий уровень" : "Уровень"), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(userData).user.coin_next_level ? formatNextLevel(_unref(userData).user.coin_next_level) : _unref(userData).user.level), 1)])]), _createElementVNode("div", _hoisted_8, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "balance_icon"
      }, null, -1)), _createElementVNode("div", _hoisted_9, _toDisplayString(formatNumber(_unref(userData).user.coin_balance)), 1)])]);
    };
  }
});