import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "event_wrapper"
};
const _hoisted_2 = {
  class: "event_title"
};
const _hoisted_3 = ["src", "alt"];
const _hoisted_4 = {
  class: "event_status"
};
const _hoisted_5 = {
  class: "event_status_info"
};
const _hoisted_6 = {
  class: "event_status_time"
};
const _hoisted_7 = {
  class: "event_reward"
};
const _hoisted_8 = {
  class: "event_reward_info"
};
const _hoisted_9 = ["src", "alt"];
const _hoisted_10 = {
  key: 0,
  class: "event_reward_rating"
};
const _hoisted_11 = {
  class: "event_actions"
};
import ButtonMain from "@/components/Libs/ButtonMain.vue";
import { Utils } from "@/assets/js/utils";
import { ref, computed } from "vue";
export default /*@__PURE__*/_defineComponent({
  __name: 'RaidBossItem',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  emits: ["click", "enter-instance"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const now = ref(Date.now());
    const rewardItems = computed(() => {
      const uniqueRewardsMap = new Map();
      props.event.rewardGroups.forEach(group => {
        group.rewards.forEach(reward => {
          if (!uniqueRewardsMap.has(reward.id)) {
            uniqueRewardsMap.set(reward.id, reward);
          }
        });
      });
      return Array.from(uniqueRewardsMap.values());
    });
    const ratingView = () => {
      console.log("rating");
    };
    const emitClick = __emit;
    const handleOpenInfo = event => {
      emitClick("click", event);
    };
    const enterInstance = () => {
      emitClick("enter-instance", props.event);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "event",
        style: _normalizeStyle({
          borderColor: __props.event.color
        }),
        onClick: _cache[0] || (_cache[0] = $event => handleOpenInfo(__props.event))
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
        src: __props.event.src,
        alt: __props.event.name
      }, null, 8, _hoisted_3), _createElementVNode("span", {
        style: _normalizeStyle({
          color: __props.event.color
        })
      }, _toDisplayString(__props.event.name), 5), _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "event_info"
      }, null, -1))]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_cache[2] || (_cache[2] = _createTextVNode(" Статус: ")), _createElementVNode("span", {
        style: _normalizeStyle({
          color: __props.event.status ? '#93DB6A' : '#FF6E6E'
        })
      }, _toDisplayString(__props.event.status ? "Доступен" : "Закрыто"), 5)]), _createElementVNode("div", _hoisted_6, _toDisplayString(__props.event.status ? "До окончания" : "Начало через") + " " + _toDisplayString(_unref(Utils).formatRemainingTimeEvent(__props.event.status ? __props.event.end : __props.event.start, now.value)), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_cache[3] || (_cache[3] = _createTextVNode(" Награда: ")), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rewardItems.value, reward => {
        return _openBlock(), _createElementBlock("img", {
          key: reward.id,
          src: reward.src,
          alt: reward.name
        }, null, 8, _hoisted_9);
      }), 128))]), __props.event.status ? (_openBlock(), _createElementBlock("div", _hoisted_10, " Ваш рейтинг: 7-е место ")) : _createCommentVNode("", true)]), _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "event_health"
      }, [_createElementVNode("div", {
        class: "event_health_bar",
        style: {
          width: 70 + '%'
        }
      })], -1))]), _createElementVNode("div", {
        class: "event_hr",
        style: _normalizeStyle({
          backgroundColor: __props.event.color
        })
      }, null, 4), _createElementVNode("div", _hoisted_11, [_createVNode(ButtonMain, {
        text: "Рейтинг",
        onClick: _withModifiers(ratingView, ["stop"]),
        fontSize: '11px',
        width: 'unset',
        padding: '5px 10px'
      }), _createVNode(ButtonMain, {
        text: "Войти в инстанс",
        fontSize: '11px',
        width: 'unset',
        padding: '5px 10px',
        onClick: _withModifiers(enterInstance, ["stop"]),
        disabled: !__props.event.status
      }, null, 8, ["disabled"])])], 4);
    };
  }
});