export enum EtcItemEnum {
  VIP = "vip",
  BOOSTER = "booster",
  AMULET = "amulet",
  COIN = "coin",
  AUTOTAP = "autotap",
  EXCLUSIVE = "exclusive",
}

export const iconMap: Record<string, string> = {
  vip_bronze: require("@/assets/images/icons/vip_bronze.svg"),
  vip_silver: require("@/assets/images/icons/vip_silver.svg"),
  vip_gold: require("@/assets/images/icons/vip_gold.svg"),
  super_vip: require("@/assets/images/icons/vip_super.svg"),
  boost: require("@/assets/images/icons/boost.svg"),
  default: require("@/assets/images/icons/modal_icon.svg"),
};
