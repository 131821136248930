import { createApp } from "vue";
import App from "./App.vue";
import "./assets/css/reset.css";
import "./assets/css/index.css";
import "./assets/css/variables.css";
import router from "./router";
import { createPinia } from "pinia";
import apiPlugin from "./plugins/apiPlugin";
import Toast from "@brackets/vue-toastification";
import "@brackets/vue-toastification/dist/index.css";

const toastOptions = {
  position: "bottom-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
};

const app = createApp(App);

app.use(router);
app.use(createPinia());
app.use(apiPlugin);
app.use(Toast, toastOptions);
app.mount("#app");
