import { UserSuccessResponse } from "@/types/user";
import { post } from "@/api/apiClient";

export const verifyUser = async (telegramData: string) => {
  const response = await post<UserSuccessResponse>("/user/verify/", {
    telegram_data: telegramData,
  });

  return response.data;
};

export const updateCoinBalance = async (click_count: number) => {
  const response = await post<UserSuccessResponse>("/user/coin_balance/", {
    click_count,
  });
  return response.data;
};
