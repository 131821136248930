import { userStore } from "@/stores/userData";

export class Main {
  moneta_path: string;
  private clickCount: number;
  private clickTimeout: ReturnType<typeof setTimeout> | null;
  private userDataStore: ReturnType<typeof userStore>;

  constructor(moneta_path: string) {
    this.moneta_path = moneta_path;
    this.clickCount = 0;
    this.clickTimeout = null;
    this.userDataStore = userStore();
    this.initButtonClick();
  }

  initButtonClick() {
    const button = document.querySelector<HTMLImageElement>(".button img");
    if (!button) return;

    const buttonContainer = button.parentElement!;
    buttonContainer.style.position = "relative";

    button.addEventListener("pointerdown", (event) => {
      button.classList.add("active");
      if (this.clickTimeout) clearTimeout(this.clickTimeout);
    });

    button.addEventListener("pointerup", this.handlePointerUp.bind(this));
    button.addEventListener("pointercancel", () => {
      button.classList.remove("active");
    });
    button.addEventListener("pointerleave", () => {
      button.classList.remove("active");
    });
  }

  handlePointerUp(event: PointerEvent) {
    const button = event.currentTarget as HTMLImageElement;
    button.classList.remove("active");

    const buttonRect = button.getBoundingClientRect();
    const { clientX, clientY } = event;
    const { left, top } = buttonRect;

    const relativeX = clientX - left;
    const relativeY = clientY - top;

    this.createCoins(relativeX, relativeY);
    this.showCoinText(
      relativeX,
      relativeY,
      this.userDataStore.user.coin_per_tap
    );

    this.clickCount++;
    this.userDataStore.user.coin_balance +=
      this.userDataStore.user.coin_per_tap;
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
    }
    this.clickTimeout = setTimeout(() => {
      this.sendClickCount();
      this.clickCount = 0;
    }, 1000);
  }

  createCoins(x: number, y: number) {
    const numCoins = Math.floor(Math.random() * 3) + 1;
    for (let i = 0; i < numCoins; i++) {
      const coin = document.createElement("img");
      coin.src = this.moneta_path;
      coin.classList.add("coin");
      coin.style.left = `${x - 10}px`;
      coin.style.top = `${y - 10}px`;
      coin.style.width = "20px";
      coin.style.height = "20px";
      coin.style.position = "absolute";

      document.querySelector(".button")!.appendChild(coin);
      coin.getBoundingClientRect();

      const dx = (Math.random() - 0.5) * 100;
      const dy = -Math.random() * 100 - 50;

      coin.style.transition = "transform 1s ease-out, opacity 1s ease-out";
      coin.style.transform = `translate(${dx}px, ${dy}px)`;
      coin.style.opacity = "0";

      setTimeout(() => {
        coin.remove();
      }, 1000);
    }
  }

  showCoinText(x: number, y: number, coinForUser: number) {
    const text = document.createElement("div");
    text.classList.add("coin-text");
    text.innerText = `+${coinForUser}`;
    text.style.left = `${x}px`;
    text.style.top = `${y}px`;

    document.querySelector(".button")!.appendChild(text);
    text.getBoundingClientRect();
    text.style.transform = `translateY(-50px)`;
    text.style.opacity = "0";
    setTimeout(() => {
      text.remove();
    }, 1000);
  }

  private async sendClickCount() {
    try {
      this.userDataStore.addCoins(this.clickCount);
    } catch (error) {
      console.error("Сетевая ошибка:", error);
    }
  }
}
