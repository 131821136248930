import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { ref } from "vue";
import ModalWindow from "./ModalWindow.vue";
import ContentCoins from "./Content/ContentCoins.vue";
import ContentVIP from "./Content/ContentVIP.vue";
import ContentBoosts from "./Content/ContentBoosts.vue";
import ContentAutoTap from "./Content/ContentAutoTap.vue";
import ContentExclusive from "./Content/ContentExclusive.vue";
// import ContentSpecial from "./Content/ContentSpecial.vue";
import ContentCases from "./Content/ContentCases.vue";
import { iconMap } from "@/enums/EtcItemEnum";
export default /*@__PURE__*/_defineComponent({
  __name: 'ShopSection',
  props: {
    shopType: {
      type: String,
      required: true
    },
    activeSection: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const isModalVisible = ref(false);
    const modalData = ref({
      id: 0,
      title: "",
      description: "",
      descriptionIcon: "",
      price: "0",
      priceIcon: "",
      iconSrc: ""
    });
    const closeModal = () => {
      isModalVisible.value = false;
    };
    const handleBuy = item => {
      console.log("Покупка товара:", item);
      console.log(item.id);
      closeModal();
    };
    const handleItemClicked = item => {
      modalData.value.id = item.id;
      modalData.value.title = item.name;
      modalData.value.description = item.description || "";
      modalData.value.descriptionIcon = getDescriptionIcon(item);
      modalData.value.price = props.shopType === "shop" ? item.price_rub : item.price_coins;
      modalData.value.priceIcon = getPriceIcon();
      modalData.value.iconSrc = iconMap[item.code] || iconMap["default"];
      isModalVisible.value = true;
    };
    const getDescriptionIcon = item => {
      if (item.code !== "boost") {
        return require("@/assets/images/icons/moneta.svg");
      }
      return "";
    };
    const getPriceIcon = () => {
      if (props.shopType === "shop") {
        return require("@/assets/images/icons/ruble.svg");
      } else if (props.shopType === "boosts") {
        return require("@/assets/images/icons/moneta.svg");
      }
      return "";
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [__props.activeSection === 'coins' ? (_openBlock(), _createBlock(ContentCoins, {
        key: 0,
        shopType: __props.shopType,
        onItemClicked: handleItemClicked
      }, null, 8, ["shopType"])) : _createCommentVNode("", true), __props.activeSection === 'vip' ? (_openBlock(), _createBlock(ContentVIP, {
        key: 1,
        shopType: __props.shopType,
        onItemClicked: handleItemClicked
      }, null, 8, ["shopType"])) : _createCommentVNode("", true), __props.activeSection === 'boosts' ? (_openBlock(), _createBlock(ContentBoosts, {
        key: 2,
        shopType: __props.shopType,
        onItemClicked: handleItemClicked
      }, null, 8, ["shopType"])) : _createCommentVNode("", true), __props.activeSection === 'auto_tap' ? (_openBlock(), _createBlock(ContentAutoTap, {
        key: 3,
        shopType: __props.shopType,
        onItemClicked: handleItemClicked
      }, null, 8, ["shopType"])) : _createCommentVNode("", true), __props.activeSection === 'exclusive' ? (_openBlock(), _createBlock(ContentExclusive, {
        key: 4,
        shopType: __props.shopType,
        onItemClicked: handleItemClicked
      }, null, 8, ["shopType"])) : _createCommentVNode("", true), __props.activeSection === 'cases' ? (_openBlock(), _createBlock(ContentCases, {
        key: 5,
        shopType: __props.shopType
      }, null, 8, ["shopType"])) : _createCommentVNode("", true), _createVNode(ModalWindow, {
        isVisible: isModalVisible.value,
        type: __props.shopType,
        item: modalData.value,
        onClose: closeModal,
        onBuy: handleBuy
      }, null, 8, ["isVisible", "type", "item"])], 64);
    };
  }
});