export class Utils {
  static formatRemainingTimeEvent = (
    timestamp: number,
    now: number
  ): string => {
    const diff = timestamp - now;

    if (diff <= 0) {
      return "Нет событий";
    }

    const oneDay = 24 * 60 * 60 * 1000;

    if (diff < oneDay) {
      const hours = Math.floor(diff / (60 * 60 * 1000));
      const minutes = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000));
      return `${hours}ч ${minutes}м`;
    } else {
      const days = Math.floor(diff / oneDay);
      return `${days}д`;
    }
  };

  static hexToRgb(hex: string): string {
    const bigint = parseInt(hex.replace("#", ""), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  }
}
