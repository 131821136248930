import { App } from "vue";
import apiClient from "@/api/apiClient";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $apiClient: typeof apiClient;
  }
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$apiClient = apiClient;
  },
};
