import { defineStore } from "pinia";
import { verifyUser, updateCoinBalance } from "@/api/userApi";
import { User } from "@/types/user";
// import { useCoinsStore } from "./coinsStore";

// const coinsStore = useCoinsStore();

export const userStore = defineStore("userData", {
  state: () => ({
    user: {} as User,
    isAuth: false,
    loading: false,
    error: null as string | null,
  }),
  actions: {
    getHash() {
      return this.user.hash || "";
    },
    async initUser(value: string) {
      this.loading = true;
      this.error = null;
      try {
        const userData = await verifyUser(value);
        this.user = userData;
        this.isAuth = true;
      } catch (err) {
        this.error = "Не удалось загрузить данные.";
      } finally {
        this.loading = false;
      }
    },

    async addCoins(click_count: number) {
      this.loading = true;
      this.error = null;
      try {
        this.user = await updateCoinBalance(click_count);
      } catch (err) {
        this.error = "Не удалось загрузить данные.";
      } finally {
        this.loading = false;
      }
    },
  },
});
